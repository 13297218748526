import { template as template_a83506251fde45f8b7e2bd1d18bb6205 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_a83506251fde45f8b7e2bd1d18bb6205(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
