import { template as template_4b9278d7cc4643e6a395f02e673816b3 } from "@ember/template-compiler";
const WelcomeHeader = template_4b9278d7cc4643e6a395f02e673816b3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
